import "./order.css";
import "./orderDetails.css";
import url from "../../config/axios";
import moment from "moment";
import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
import Popup from "reactjs-popup";
export default function OrderDetail() {
  const order = JSON.parse(localStorage.getItem("singleOrder"));
  const admin = JSON.parse(localStorage.getItem("Admin"));
  const [orderStatus, setOrderStatus] = useState("Pick Up");
  const [loyaltyStandards, setLoyaltyStandards] = useState();
  const history = useHistory();
  const [loyaltyPoints, setLoyaltyPoints] = useState(0);
  const [customerPoints, setCustomerPoints] = useState(0);
  // const [loyalty, setLoyalty] = useState();
  const [singleOrder, setSingleOrder] = useState(false);
  const [disable, setDisable] = useState(
    order.status === "Dispatched" ? true : false
  );

  const ref = useRef();
  const openTooltip = () => ref.current.open();
  const closeTooltip = () => {
    ref.current.close();
  };
  const [message, setMessage] = useState({
    display: false,
    status: false,
    text: "",
  });
  // let loyaltyPoints = 0;
  // let customerPoints = 0;
  useEffect(() => {
    // console.log(order);
    setOrderStatus(order.status);
    GetLoyaltyStandards();
    GetCustomerOrder();
    GetLoyaltyPoints();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  let weekdays = [
    "sunday",
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
  ];
  let date = new Date(order.createdAt);
  console.log(weekdays[date.getDay()], "Day");
  console.log(date.getHours(), "Hour");

  const GetLoyaltyStandards = async () => {
    await url
      .get(`/loyalty?consumerId=${admin[0].consumerId}`)
      .then(async (res) => {
        console.log("response", res);
        if (res.data.Message === "Success") {
          await setLoyaltyStandards();
          setLoyaltyPoints(
            res.data.data[weekdays[date.getDay()]][date.getHours()]
          );
          console.log(loyaltyPoints, "POINTS");
        } else {
          alert(res.data.data);
        }
      })
      .catch((e) => console.log(e));
  };

  const GetLoyaltyPoints = async () => {
    await url
      .get(
        `/loyaltyPoints/id?userID=${order.customer.customerID}&outletID=${order.outletID}`
      )
      .then(async (res) => {
        console.log("LOTY", res);
        if (res.data.Message === "Success") {
          setCustomerPoints(res.data.data.points);
        } else {
          let body = {
            userID: order.customer.customerID,
            points: 0,
          };
          await url
            .post(`/loyaltyPoints?outletID=${admin[0].outletChainID}`, body)
            .then(async (res) => {
              if (res.data.Message === "Success") {
                console.log(res, "LOYALTY POINTS CREATED");
              } else {
                alert(res.data.data);
              }
            });
        }
      })
      .catch((e) => console.log(e));
  };

  const GetCustomerOrder = async () => {
    await url
      .get(`/order/customer/?customerID=${order.customer.customerID}`)
      .then(async (res) => {
        console.log("ORDERS", res);
        if (res.data.Message === "Success") {
          if (res.data.data.length === 1) {
            setSingleOrder(true);
          }
        } else {
          alert(res.data.data);
        }
      })
      .catch((e) => console.log(e));
  };

  const updateStatus = async () => {
    url
      .put(`/order/?orderID=${order.orderID}`, { status: orderStatus })
      .then(async (res) => {
        console.log("response", res);
        if (res.data.Message === "Success") {
          closeTooltip();
          if (admin && admin[0].isSubOutlet) {
            if (res.data.data.status === "Dispatched") {
              setDisable(true);
              // if (singleOrder === true) {
              //   await setLoyaltyPoints(
              //     customerPoints +
              //       loyaltyPoints +
              //       loyaltyStandards.PointsOnFirstOrder
              //   );
              // } else {
              //   await setLoyaltyPoints(customerPoints + loyaltyPoints);
              // }

              await url
                .put(
                  `/loyaltyPoints/earn?userID=${order.customer.customerID}&outletID=${admin[0].outletChainID}`,
                  {
                    points: singleOrder
                      ? customerPoints +
                      loyaltyPoints +
                      loyaltyStandards.PointsOnFirstOrder
                      : customerPoints + loyaltyPoints,
                  }
                )
                .then(async (res) => {
                  console.log(res, "RES: ");
                  setMessage({
                    status: false,
                    display: true,
                  });
                  openTooltip();
                  setTimeout(() => {
                    history.push("/order-outlet");
                  }, 3000);
                })
                .catch((e) => {
                  console.log(e);
                });
            }
          } else {
            // history.push("/order");
          }
        } else {
          console.log(res.data.data);
        }
      })
      .catch((e) => console.log(e));
  };

  // const getOutlet = () => {
  //     url.get(``)
  //         .then(async (res) => {
  //             console.log('res', res)
  //             if (res.data.Message === "Success") {
  //                 setOutlet(res.data.data);
  //             }
  //             else {
  //                 alert(res.data.data);
  //             }
  //         })
  //         .catch((e) => console.log(e));
  // }

  function checkColor(value) {
    let result;
    switch (value) {
      case "Pending":
        result = "btn button-color-red";
        break;
      case "New":
        result = "btn button-color-blue";
        break;
      case "Cancelled":
        result = "btn button-color-brown";
        break;
      case "Delivered":
        result = "btn button-color-purple";
        break;
      case "Dispatched":
        result = "btn button-color-green";
        break;
      case "Pick Up":
        result = "btn button-color-darkgreen";
        break;
      default:
        result = "";
        break;
    }
    return result;
  }

  const subTotalPrice = parseFloat(order.totalPrice ?? 0).toFixed(2);
  const discountPrice = parseFloat(order.discount ?? 0).toFixed(2);
  // const totalTax = parseFloat(
  //   (+subTotalPrice - +discountPrice) *
  //     ((process.env.REACT_APP_TAX_RATE ?? 15) / 100)
  // ).toFixed(2);
  const totalTax = parseFloat(
    +subTotalPrice * ((process.env.REACT_APP_TAX_RATE ?? 0) / 100)
  ).toFixed(2);
  const brokenArrowCityTax = parseFloat(
    +subTotalPrice *
    ((process.env.REACT_APP_BROKEN_ARROW_CITY_TAX_RATE ?? 0) / 100)
  ).toFixed(2);
  const ommaTax = parseFloat(
    +subTotalPrice * ((process.env.REACT_APP_OMMA_TAX_RATE ?? 0) / 100)
  ).toFixed(2);
  const okStateSalesTax = parseFloat(
    +subTotalPrice *
    ((process.env.REACT_APP_OK_STATE_SALES_TAX_RATE ?? 0) / 100)
  ).toFixed(2);
  const tulsaCountryTax = parseFloat(
    +subTotalPrice * ((process.env.REACT_APP_TULSA_TAX_RATE ?? 0) / 100)
  ).toFixed(2);
  // const payableAmount = parseFloat(
  //   +subTotalPrice - +discountPrice + +totalTax
  // ).toFixed(2);
  return (
    <div className="row">
      <div className="col-md-7">
        <div className="row">
          <div className="col-md-12">
            <div className="card-new usr-m-b">
              <HighlightOffRoundedIcon
                style={{
                  cursor: "pointer",
                  position: "absolute",
                  right: "0",
                  top: "0",
                  transform: "translateY(50%) translateX(-100%)",
                }}
                onClick={() => history.goBack()}
              />
              <div className="card-history-user order-detail">
                {/* <h5>Order Details</h5> */}
                <div
                  className="d-flex justify-content-between"
                  style={{ color: "gray" }}
                >
                  <h6 style={{ width: "40%" }}>Placed on:</h6>
                  <h6 style={{ width: "30%" }}>Payment Status:</h6>
                  <h6 style={{ width: "30%" }}>Seller:</h6>
                </div>
                <div
                  className="d-flex justify-content-between"
                  style={{ color: "black" }}
                >
                  <h6 style={{ width: "40%" }}>
                    {moment(order.createdAt).format("MMM. Do YYYY, h:mm A")}
                  </h6>
                  <h6 style={{ width: "30%" }}>
                    <button className={checkColor(order.status)}>
                      {order.status}
                    </button>
                  </h6>
                  <h6 style={{ width: "30%" }}>{admin[0].address}</h6>
                </div>
                <div class="border-top my-3"></div>

                {order.products.length >= 0 &&
                  order.products.map((single) => {
                    console.log(single, "single");
                    if (+single.quantity <= 0) {
                      return null;
                    }
                    return (
                      <div
                        style={{
                          padding: "0px",
                        }}
                        className="table-mod custom-scrollbar order-data"
                      >
                        <div className="row-cust-ac ">
                          <div className="cust-row-ac-img">
                            <img
                              alt=""
                              src={single.image}
                              style={{ paddingRight: "10px" }}
                              width="100%"
                            />
                          </div>
                          <div className="cust-row-ac-body">{single.name}</div>
                          <div className="cust-row-ac-desc">
                            <span>
                              Brand :
                              <span className="ac-green">
                                {" "}
                                {single.brandName}
                              </span>
                            </span>
                            <br /> <br />
                            <span className="acseller">
                              Seller :
                              <span className="ac-green">
                                {" "}
                                {admin[0].address}
                              </span>
                            </span>
                          </div>
                          <div
                            className="cust-row-ac-price"
                            style={{ justifyContent: "right" }}
                          >
                            {single.quantity} x $
                            {" " +
                              parseFloat(
                                single.price
                                  ? single.price
                                  : single?.variant?.price ?? 0
                              ).toFixed(2) +
                              " "}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                <div class="border-top my-3"></div>
                <br />
                <div className="row">
                  <div className="col-lg-6"></div>
                  <div className="col-lg-6">
                    <div className="d-flex justify-content-between">
                      <span style={{ color: "#8C8C8C" }}>Subtotal</span>
                      <span className="black-color">
                        $ &nbsp;{subTotalPrice}
                      </span>
                    </div>

                    <div class="border-top my-3"></div>
                    <div className="d-flex justify-content-between">
                      <span style={{ color: "#8C8C8C" }}>Discount</span>
                      <span className="black-color">
                        $ &nbsp;{discountPrice}
                      </span>
                    </div>
                    <div class="border-top my-3"></div>
                    {/* <div className="d-flex justify-content-between">
                      <span style={{ color: "#8C8C8C" }}>Shipping</span>
                      <span className="black-color">$0</span>
                    </div>
                    <div class="border-top my-3"></div> */}
                    <div className="d-flex justify-content-between">
                      <span style={{ color: "#8C8C8C" }}>Tax(included)</span>
                      <span className="black-color">$ &nbsp;{totalTax}</span>
                    </div>
                    <div class="border-top my-3"></div>
                    <div className="d-flex justify-content-between">
                      <span style={{ color: "#8C8C8C" }}>OMMA TAX</span>
                      <span className="black-color">$ &nbsp;{ommaTax}</span>
                    </div>
                    <div class="border-top my-3"></div>
                    <div className="d-flex justify-content-between">
                      <span style={{ color: "#8C8C8C" }}>
                        Broken Arrow City Tax
                      </span>
                      <span className="black-color">
                        $ &nbsp;{brokenArrowCityTax}
                      </span>
                    </div>
                    <div class="border-top my-3"></div>
                    <div className="d-flex justify-content-between">
                      <span style={{ color: "#8C8C8C" }}>
                        OK State Sales Tax
                      </span>
                      <span className="black-color">
                        $ &nbsp;{okStateSalesTax}
                      </span>
                    </div>
                    <div class="border-top my-3"></div>
                    <div className="d-flex justify-content-between">
                      <span style={{ color: "#8C8C8C" }}>
                        Tulsa Country Tax
                      </span>
                      <span className="black-color">
                        $ &nbsp;{tulsaCountryTax}
                      </span>
                    </div>
                    <div class="border-top my-3"></div>
                    <div className="d-flex justify-content-between">
                      <span style={{ color: "#8C8C8C" }}>Total</span>
                      <span className="black-color">
                        $ &nbsp;{subTotalPrice}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="col-md-5">
        <div className="row">
          {/* <div className="col-md-12">
            <div className="card-new usr-m-b">
              <div className="card-history-user"></div>
            </div>
          </div> */}
          {/* <div className="col-md-12">
                        <div className="card-new usr-m-b">
                            <div className="card-history-user mt-4" style={{ height: '500px' }}>
                                <h5>Shipping details</h5>
                                <div>

                                </div>
                            </div>
                        </div>
                    </div> */}
          <div className="col-md-12">
            <div className="card-new usr-m-b">
              <div
                className=""
              // style={{ height: "250px" }}
              >
                <h4 style={{ fontSize: "18px" }}>Billing details</h4>
                <div>
                  <h6>{order.customer.name}</h6>
                  {!!order.customer?.phone && (
                    <h6 style={{ color: "#6B94FF" }}>
                      <i
                        className="fa fa-phone-alt"
                        style={{ paddingRight: "15px" }}
                      ></i>
                      {/* +1-245-698-2215 */}
                      {order.customer.phone}
                    </h6>
                  )}
                  <h6 style={{ color: "#6B94FF" }}>
                    <i
                      className="fa fa-envelope"
                      style={{ paddingRight: "15px" }}
                    ></i>
                    {order.customer.email}
                  </h6>
                </div>
                {/* <div class="border-top my-3"></div>
                <h4 style={{ fontSize: "18px" }}>Address</h4>
                36 Meryl Street, Broadway, California 90011 */}
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="card-new usr-m-b">
              <div
                className="card-history-user"
              // style={{ height: "110px" }}
              >
                <h4 style={{ fontSize: "18px" }}>Update Status</h4>

                <div className="" style={{ display: "flex", marginTop: "2em" }}>
                  <select
                    className="form-control"
                    style={{ width: "50%" }}
                    id="exampleFormControlSelect1"
                    value={orderStatus}
                    onChange={(e) => setOrderStatus(e.target.value)}
                    disabled={disable}
                  >
                    {/* <option value={orderStatus}>{orderStatus}</option>  */}
                    <option value="Pick Up">Pick Up</option>
                    <option value="New">New</option>
                    <option value="Pending">Pending</option>
                    <option value="Dispatched">Dispatched</option>
                    <option value="Delivered">Delivered</option>
                  </select>
                  <button
                    className="btn btn-primary ml-2"
                    onClick={() => {
                      setMessage({
                        status: true,
                        display: true,
                      });
                      openTooltip();
                    }}
                  >
                    Update
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Popup ref={ref} position="center" onClose={closeTooltip}>
        {message.status ? (
          <div style={{ textAlign: "center" }}>
            <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
            <p className="popup-heading">
              Are you sure you want to update the status ?
            </p>
            <button
              className="btn btn-cannaby"
              onClick={() => {
                updateStatus();
              }}
            >
              Yes
            </button>
            <button
              style={{ marginLeft: "20px", marginTop: "30px" }}
              type="button"
              className="btn popup-cancel-button"
              onClick={closeTooltip}
            >
              No
            </button>
          </div>
        ) : (
          <div style={{ textAlign: "center" }}>
            <i
              style={{
                color: "green",
                fontSize: "70px",
              }}
              className="fa fa-check"
            ></i>
            <p style={{ marginTop: "20px" }}>
              {loyaltyPoints} points awarded to {order.customer.name}
            </p>
            {/* <button
              style={{ marginTop: "10px" }}
              type="button"
              onClick={closeTooltip}
              className="btn btn-primary"
            >
              Close
            </button> */}
          </div>
        )}
      </Popup>
    </div>
  );
}
