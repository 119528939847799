/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef } from "react";
import "./Login.css";
import url from "../../config/axios";
import brandImage from "../../assets/brandImage.png";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import topVector from "../../assets/LoginTopDesign.jpg";
import leftVector from "../../assets/LoginLeftDesign.jpg";
// import rightVector from "../../assets/LoginRightVector.jpg";
import bottomVector from "../../assets/LoginBottomDesign.jpg";
import { Carousel } from "react-responsive-carousel";
import Iphone1 from "../../assets/GroupIphone.png";
// import firebase from "firebase/app";
// import "firebase/messaging";
import isEmail from "email-validator";

import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";

function Login() {
  const ref = useRef();
  const openTooltip = () => ref.current.open();
  const closeTooltip = () => ref.current.close();

  let history = useHistory();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  // const [testEmail, setTestEmail] = useState(false);
  // const [token, setToken] = useState("");
  // const [emailValid, setEmailValid] = useState(true);
  const [loading, setLoading] = React.useState(true);
  const [validationMessages, setvalidationMessages] = React.useState({
    email: "",
    password: "",
  });

  const [message, setMessage] = useState({
    display: false,
    status: false,
    text: "",
  });
  // const regex = new RegExp("^[A-Za-z0-9_.@]+$");

  const validateForm = async () => {
    // console.log(regex.test(email), "EMAIL");
    // await setTestEmail(regex.test(email));
    let data = {};
    //validate email
    if (email.trim().length === 0) {
      data = { email: "Email is required" };
      setvalidationMessages({ ...data });
      return data;
    } else if (!isEmail.validate(email)) {
      data = { email: "Email is not valid" };
      setvalidationMessages({ ...data });
      return data;
    } 
    // else if (!regex.test(email)) {
    //   data = { email: "Email contains invalid character" };
    //   setvalidationMessages({ ...data });
    //   return data;
    // }

    if (Object.keys(data).length === 0) {
      //validate password
      if (password.trim().length === 0) {
        data = { password: "Password is required" };
        setvalidationMessages({ ...data });
        return data;
      } else if (password.trim().length < 6) {
        data = {
          password: "Password must of at least 6 characters long",
        };
        setvalidationMessages({ ...data });
        return data;
      }
    }
    setvalidationMessages({ ...data });
    return data;
  };
  const [rememberMeState, setRememberMeState] = useState({
    rememberMe: false,
    email: "",
    password: "",
  });

  const handleRememberMe = async () => {
    if (rememberMeState.rememberMe && !loading) {
      await localStorage.setItem(
        "remeberMeState",
        JSON.stringify({ rememberMe: true, email: email, password: password })
      );
    } else if (!loading) {
      await localStorage.removeItem("remeberMeState");
    } else {
      const { password, ...others } = validationMessages;
      setvalidationMessages({ ...others });
    }
  };
  const [triedBefore, setTriedBefore] = useState(false);
  useEffect(() => {
    handleRememberMe();
    if (triedBefore) {
      validateForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email, password, rememberMeState]);

  //check if previously logged in or not
  useEffect(() => {
    try {
      const adminID = JSON.parse(localStorage.getItem("Admin"));
      if (adminID && Array.isArray(adminID) && adminID[0]?.adminID) {
        history.push("/dashboard");
      }
    } catch (e) {
      //do nothing
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //for getting autifill form data from rememberme state cache
  useEffect(() => {
    try {
      const tempRemeberMeState = JSON.parse(
        localStorage.getItem("remeberMeState") ?? "{}"
      );
      // console.log(`Got cached : `, tempRemeberMeState);
      if (tempRemeberMeState?.rememberMe) {
        console.log(`Here i am...`);
        setRememberMeState({ ...tempRemeberMeState });
        setEmail(tempRemeberMeState.email);
        setPassword(tempRemeberMeState.password);
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  }, []);

  // const messaging = firebase.messaging();

  // useEffect(() => {
  //   messaging
  //     .getToken(messaging, {
  //       vapidKey:
  //         "BOwbFLnlpXAFLnHnoKSVjzGxfY6rBgXDdxP_4lezcbwZVVw7YDHpSyVCx4fIGusHxIjYm2Ox0yFmiISo7xNEe4Q",
  //     })
  //     .then(async (currentToken) => {
  //       if (currentToken) {
  //         await setToken(currentToken);
  //         // console.log("Firebase Token", currentToken);
  //       } else {
  //         // Show permission request UI
  //         console.log(
  //           "No registration token available. Request permission to generate one."
  //         );
  //         // ...
  //       }
  //     })
  //     .catch((err) => {
  //       console.log("An error occurred while retrieving token. ", err);
  //       // ...
  //     });
  // });

  //   const messaging = firebase.messaging();

  //   messaging
  //     .getToken({
  //       vapidKey:
  //         "BOwbFLnlpXAFLnHnoKSVjzGxfY6rBgXDdxP_4lezcbwZVVw7YDHpSyVCx4fIGusHxIjYm2Ox0yFmiISo7xNEe4Q",
  //     })
  //     .then((currentToken) => {
  //       if (currentToken) {
  //         // Send the token to your server and update the UI if necessary]
  //         console.log(currentToken);
  //         // ...
  //       } else {
  //         // Show permission request UI
  //         console.log(
  //           "No registration token available. Request permission to generate one."
  //         );
  //         // ...
  //       }
  //     })
  //     .catch((err) => {
  //       console.log("An error occurred while retrieving token. ", err);
  //       // ...
  //     });

  const loginUser = async () => {
    setTriedBefore(true);
    let data = await validateForm();
    if (Object.keys(data).length === 0) {
      setLoading(true);
      url
        .post(`/oca/login/`, {
          email: email,
          password: password,
          userID: "12345",
          // Token: token,
        })
        .then(async (res) => {
          setLoading(false);
          console.log(res.data.data);
          if (res.data.Message === "Success") {
            await localStorage.setItem("Admin", JSON.stringify(res.data.data));
            // alert("LoggedIn Successfully");
            if (res.data.data[0].isSubOutlet) {
              // await history.push("/inventory-outlet");
              setMessage({
                text: "Successfully Logged In",
                status: true,
                display: true,
              });
              openTooltip();
              setTimeout(async () => {
                // await history.push("/dashboard");
                //for reloading favicon
                window.location.reload();
              }, 1000);
            } else {
              //for reloading favicon
              window.location.reload();
              // await history.push("/dashboard");
            }
          } else {
            console.log("invalid");
            setMessage({
              text: "Invalid Email or Password",
              status: false,
              display: true,
            });
            openTooltip();
          }
        })
        .catch((e) => {
          console.log(e);
          setLoading(false);
          setMessage({
            text: "Invalid Email or Password",
            status: false,
            display: true,
          });
          openTooltip();
        });
    }
  };

  const handleChange = async (value) => {
    await setEmail(value);
    // validateForm();
    // if (isEmail(value)) {
    //   setEmailValid(true);
    // } else {
    //   setEmailValid(false);
    // }
  };

  return (
    <div className="row">
      <div className="col-md-12 login" style={{ padding: "0" }}>
        <div></div>
        <form
          className="col-md-7 login-left"
          onSubmit={(e) => {
            e.preventDefault();
            if (!loading || !message.status) {
              loginUser();
            }
          }}>
          <div className="logoAuth">
            <img alt="yo1" src={brandImage} className="img-fluid " width="75" />
          </div>
          <div className="">
            <div className="group-comb ">
              <h5 style={{ marginBottom: "15px" }}>Welcome back! Sign in...</h5>
              <div className="form-group group-comb-2">
                <label htmlFor="exapleFormControlInput1">Email</label>
                <input
                  type="text"
                  value={email}
                  autoComplete={false}
                  onChange={(e) => handleChange(e.target.value)}
                  className="form-control"
                  id="exampleFormControlInput1"
                  placeholder="admin@xyz.com"
                />
              </div>
              {validationMessages.email ? (
                <span className="validation-help">
                  {validationMessages.email}
                </span>
              ) : validationMessages.email === "" ? (
                ""
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="">
            <div className="group-comb ">
              <div
                className="form-group group-comb-2"
                style={{ position: "relative" }}>
                <label htmlFor="exampleFormControlInput">Password</label>
                <input
                  type={showPassword ? "text" : "password"}
                  value={password}
                  autoComplete={false}
                  onChange={(e) => {
                    setPassword(e.target.value);
                    // validateForm();
                  }}
                  className="form-control"
                  id="exampleForControlInput1"
                  placeholder="password"
                />
                {showPassword ? <i
                  className="fa fa-eye"
                  onClick={() => setShowPassword(!showPassword)}
                  style={{
                    position: "absolute",
                    right: "20px",
                    bottom: "20px",
                    cursor: "pointer",
                  }}
                ></i> :

                  <i class="fa fa-eye-slash"
                    onClick={() => setShowPassword(!showPassword)} style={{
                      position: "absolute",
                      right: "20px",
                      bottom: "20px",
                      cursor: "pointer",
                    }}></i>
                }
              </div>
              {validationMessages.password ? (
                <span className="validation-help">
                  {validationMessages.password}
                </span>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className=" rememberMe">
            <input
              type="checkbox"
              style={{ height: "18px", width: "18px" }}
              id=""
              name=""
              checked={rememberMeState.rememberMe}
              onChange={() =>
                setRememberMeState({
                  ...rememberMeState,
                  rememberMe: !rememberMeState.rememberMe,
                })
              }
            />{" "}
            Remember me
          </div>
          <br />

          <div className="">
            <button
              type="submit"
              className="btn btn-success login-btn"
              disabled={loading || message.status}>
              {loading
                ? "Logging in..."
                : message.status
                  ? "Redirecting..."
                  : "Login"}
            </button>
          </div>
          <div className="forgot-pass">
            <a href="#"> Forgot password ?</a>
          </div>

          <img alt="vector.jpg" className="top-vector" src={topVector} />
          <img alt="vector.jpg" className="left-vector" src={leftVector} />
          <img alt="vector.jpg" className="bottom-vector" src={bottomVector} />
        </form>

        <div
          className="col-lg-5 login-right"
          style={{
            backgroundColor: "#E9FFF3",
          }}>
          <div style={{ zIndex: "10" }}>
            <Carousel
              showThumbs={false}
              infiniteLoop={true}
              showArrows={false}
              showStatus={false}
              interval={5000}
              autoPlay={true}>
              <div>
                <img
                  alt="vector.jpg"
                  src={Iphone1}
                  style={{ width: "408px", height: "500px" }}
                />
              </div>
              <div>
                <img
                  alt="vector.jpg"
                  src={Iphone1}
                  style={{ width: "408px", height: "500px" }}
                />
              </div>
              <div>
                <img
                  alt="vector.jpg"
                  src={Iphone1}
                  style={{ width: "408px", height: "500px" }}
                />
              </div>
            </Carousel>
          </div>

          <div className="login-right-div">
            {/* <h5>Lorem ipsum dolor samet</h5>
            <p style={{ color: "#999999" }}>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry.
              <br /> Lorem Ipsum is simply dummy text of the printing
            </p> */}
          </div>
          {/* <img alt="vector.jpg" className="right-vector" src={rightVector} /> */}
        </div>

        <Popup ref={ref} position="center">
          {message.status ? (
            <div style={{ textAlign: "center" }}>
              <i class="fa fa-check" style={{
                color: "green",
                fontSize: "70px",
              }}></i>
              <h2 className="popup-heading" style={{ marginTop: "20px" }}>
                Success
              </h2>
              <p>{message.text}</p>
            </div>
          ) : (
            <div style={{ textAlign: "center" }}>
              <i
                style={{
                  color: "red",
                  fontSize: "70px",
                }}
                className="fa fa-ban"></i>
              <h2 style={{ marginTop: "20px" }}>Failed</h2>
              <p className="popup-heading" style={{ marginTop: "20px" }}>
                {message.text}
              </p>
              <button
                style={{ marginTop: "10px" }}
                type="button"
                onClick={closeTooltip}
                className="btn btn-primary">
                Try Again
              </button>
            </div>
          )}
        </Popup>
      </div>
    </div>
  );
}

export default Login;
