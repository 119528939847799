import React, { useRef } from "react";
import "./discounts.css";
import url from "../../../config/axios";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";
import Popup from "reactjs-popup";
export default function Coupons() {
  const history = useHistory();
  // eslint-disable-next-line no-unused-vars
  const [outlets, setOutlets] = useState([]);
  const [coupons, setCoupons] = useState([]);
  const [totalPage, setTotalPage] = useState("");
  // const [minPro, setminPro] = useState("");
  // const [page, setPage] = useState(1);
  const adminID = JSON.parse(localStorage.getItem("Admin"));
  const [searchTerm, setSearchTerm] = useState("");
  const [unfiltered, setUnfiltered] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  var perPage = 10;
  const notFoundRef = useRef();
  const openNotFoundPopUp = () => notFoundRef.current.open();
  const closeNotFoundPopUp = () => {
    notFoundRef.current.close();
    setSearchTerm("");
  };
  //clientSide search
  useEffect(
    () => {
      if (searchTerm.trim().length !== 0) {
        let filtered = unfiltered.filter((element) =>
          element.couponCode
            .toLowerCase()
            .includes(searchTerm.trim().toLowerCase())
        );
        if (filtered.length === 0) {
          openNotFoundPopUp();
        }
        setCoupons(filtered);
        // setTotalPage(filtered.length);
        // setminPro(page * 50 - 50 + 1);
        setTotalPage(Math.ceil(filtered.length / perPage));
        setCurrentPage(1);
      } else {
        setCoupons(unfiltered);
        // setTotalPage(unfiltered.length);
        // setminPro(page * 50 - 50 + 1);
        setTotalPage(Math.ceil(unfiltered.length / perPage));
        setCurrentPage(1);
      }
    },
    // eslint-disable-next-line
    [searchTerm]
  );
  const incrementPage = () => {
    // if (totalPage < 50) {
    // } else {
    //   setPage(page + 1);
    // }
    if (currentPage < totalPage) {
      setCurrentPage(currentPage + 1);
    }
  };

  const decrementPage = () => {
    // if (page > 1) {
    //   setPage(page - 1);
    // }
    if (currentPage >= 2) {
      setCurrentPage(currentPage - 1);
    }
  };
  useEffect(() => {
    GetAllOutlets();
    GetAllCoupons();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const GetAllOutlets = () => {
    const adminID = JSON.parse(localStorage.getItem("Admin"));
    // console.log(adminID[0].adminID);
    url
      .get(`/oc/?consumerId=${adminID[0].consumerId}`)
      .then(async (res) => {
        // console.log('res', res)
        if (res.data.Message === "Success") {
          if (res.data.data.length === 0) {
            openNotFoundPopUp()
          }
          setOutlets(res.data.data);
        }
      })
      .catch((e) => console.log(e));
  };

  const ref = useRef();
  const openTooltip = (couponId) => {
    if (!!couponId) {
      setCouponId(couponId);
      ref.current.open();
    }
  };
  const closeTooltip = (success = false) => {
    if (success) {
      ref.current.close();
      GetAllCoupons();
    } else {
      ref.current.close();
    }
  };
  const [couponId, setCouponId] = useState(null);
  const deleteCoupon = () => {
    url.delete(`/coupon/?couponId=${couponId}`).then(async (res) => {
      // console.log(res, 'Coupon Deleted')
      if (res.data.Message === "Success") {
        // openTooltip();
        closeTooltip(true);
      } else {
        // alert(res.data.data);
      }
    });
  };

  const GetAllCoupons = () => {
    url
      .get(
        `/coupon/outlet/?consumerId=${adminID[0].consumerId}&outletChainID=${adminID[0].outletChainID}`
      )
      .then(async (res) => {
        console.log('coupons:', res)
        if (res.data.Message === "Success") {
          if (res.data.data.length === 0) {
            openNotFoundPopUp()
          }
          let modified = res.data?.data?.reverse() ?? [];
          setCoupons(modified);
          setUnfiltered(modified);
          // setTotalPage(res.data.data.length);
          // setminPro(page * 50 - 50 + 1);
          setTotalPage(Math.ceil(modified.length / perPage));
        }
        else {
          openNotFoundPopUp()
        }
      })
      .catch((e) => openNotFoundPopUp());
  };

  const editCoupon = (data) => {
    localStorage.setItem("couponEdit", JSON.stringify(data));
    history.push("/edit-coupon");
  };

  // const SearchByCoupon = (value) => {
  //   if (!value) {
  //     GetAllCoupons();
  //   } else {
  //     url
  //       .get(
  //         `/coupon/search/?consumerId=${adminID[0].consumerId}&name=${value}`
  //       )
  //       .then(async (res) => {
  //         // console.log(res, 'response:')
  //         if (res.data.Message === "Success") {
  //           setCoupons(res.data.data);
  //         } else {
  //           alert(res.data.data);
  //         }
  //       });
  //   }
  // };

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="card card-pro">
          {/* <button
            className="btn btn-primary btn-pro mr-5"
            onClick={() => history.push("/add-coupon")}>
            <i className="fas fa-plus-circle"></i>
            Add Coupon
          </button> */}
          {/* onClick={() => {
              history.push("/addCatalog");
            }} */}
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-6">
                  <div className="input-group">
                    <input
                      type="text"
                      onChange={(e) => setSearchTerm(e.target.value)}
                      value={searchTerm}
                      className="form-control table-search"
                      placeholder="Search discounts"
                      aria-label="Input group example"
                      aria-describedby="btnGroupAddon"
                    />
                    <div className="input-group-prepend">
                      <div
                        className="input-group-text table-search-icon"
                        id="btnGroupAddon"
                      >
                        <i className="fas fa-search"></i>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-2 filters-bar">
                  <button
                    className="btn btn-primary"
                    onClick={() => history.push("/add-coupon")}
                  >
                    <i className="fas fa-plus-circle"></i>
                    Add Coupon
                  </button>
                  <div className="dropdown">
                    <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuLink"
                    >
                      <a className="dropdown-item" href="/">
                        Action
                      </a>
                      <a className="dropdown-item" href="/">
                        Another action
                      </a>
                      <a className="dropdown-item" href="/">
                        Something else here
                      </a>
                    </div>
                  </div>
                  <div className="dropdown">
                    {/* <a
                      className="btn cannaby-light"
                      href="/"
                      role="button"
                      id="dropdownMenuLink"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <i className="fas fa-filter"></i>
                    </a> */}

                    <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuLink"
                    >
                      <a className="dropdown-item" href="/">
                        Action
                      </a>
                      <a className="dropdown-item" href="/">
                        Another action
                      </a>
                      <a className="dropdown-item" href="/">
                        Something else here
                      </a>
                    </div>
                  </div>
                </div>
                {!!coupons.length && (
                  <div className="col-md-4 pagination">
                    <span>
                      {`${perPage * (currentPage - 1) + 1} - ${perPage * (currentPage - 1) +
                        coupons.slice(
                          (currentPage - 1) * perPage,
                          currentPage * perPage
                        ).length
                        } of ${coupons?.length ?? 0}`}
                    </span>
                    <div
                      className="btn cannaby-light"
                      onClick={() => decrementPage()}
                      role="button"
                    >
                      <i className="fas fa-chevron-left"></i>
                    </div>
                    <div
                      className="btn cannaby-light"
                      onClick={() => incrementPage()}
                      role="button"
                    >
                      <i className="fas fa-chevron-right"></i>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="col-md-12">
              <table className="table table-striped-cust">
                <thead>
                  <tr>
                    <th scope="col">
                      <span
                        style={{
                          fontSize: "100%",
                        }}
                      >
                        Coupon Code
                      </span>
                    </th>
                    <th scope="col"></th>

                    <th scope="col"></th>
                    <th scope="col"></th>
                    {/* <th scope="col">Applicable to</th> */}
                    <th scope="col">
                      <span
                        style={{
                          fontSize: "100%",
                        }}
                      >
                        Value
                      </span>
                    </th>
                    <th scope="col">
                      <span
                        style={{
                          fontSize: "100%",
                        }}
                      >
                        Expires On
                      </span>
                    </th>
                    <th scope="col">
                      <span
                        style={{
                          fontSize: "100%",
                        }}
                      >
                        Usage
                      </span>
                    </th>
                    <th scope="col">
                      <span
                        style={{
                          fontSize: "100%",
                        }}
                      >
                        Status
                      </span>
                    </th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {
                    coupons.length !== 0 ?
                      coupons
                        .slice((currentPage - 1) * perPage, currentPage * perPage)
                        .map((coupon, i) => {
                          console.log(coupons.length, 'coupon:');
                          return (

                            <tr key={i}>
                              <td colSpan="4">{coupon.couponCode}</td>

                              <td>
                                {coupon.couponType === "Free Shiping"
                                  ? "Free Shipping"
                                  : coupon.couponType === "Percentage"
                                    ? `${coupon.discountValue ?? 0} %`
                                    : `$ ${coupon.discountValue ?? 0}`}
                              </td>
                              <td>
                                {!!coupon.neverExpires
                                  ? "Never Expires"
                                  : moment(coupon.endDate).format(
                                    "MMMM Do YYYY, h:mm A"
                                  )}
                              </td>
                              <td className="usage">
                                <span>
                                  {coupon.rules?.TotalUsageLimit || "N/A"}
                                </span>
                              </td>
                              <td className="status">
                                {coupon.status ? (
                                  <span>Enabled</span>
                                ) : (
                                  <span style={{ color: "red" }}>Disabled</span>
                                )}
                              </td>
                              <td>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "2em",
                                  }}
                                >
                                  <i
                                    className="fas fa-pen"
                                    onClick={() => {
                                      editCoupon(coupon);
                                    }}
                                  ></i>
                                  {/* onClick={() => moveToEdit(product)} */}
                                  <i
                                    className="fa fa-trash-alt"
                                    style={{ color: "red", cursor: "pointer" }}
                                    onClick={() => {
                                      openTooltip(coupon.couponId);
                                    }}
                                  ></i>
                                </div>
                              </td>
                            </tr>


                          );
                        })

                      :

                      <>   <tr>
                        <td colSpan="12" style={{ textAlign: "center" }}>
                          No Coupons found</td>
                      </tr></>

                  }
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <Popup ref={ref} position="center">
        <div style={{ textAlign: "center" }}>
          <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
          <p className="popup-heading">Do you want to delete this coupon ?</p>
          <button
            className="btn btn-cannaby"
            onClick={() => {
              deleteCoupon();
            }}
          >
            Yes
          </button>
          <button
            style={{ marginLeft: "20px", marginTop: "30px" }}
            type="button"
            className="btn popup-cancel-button"
            onClick={closeTooltip}
          >
            No
          </button>
        </div>
      </Popup>
      <Popup ref={notFoundRef} position="center">
        <div style={{ textAlign: "center" }}>
          <i
            style={{
              color: "red",
              fontSize: "70px",
            }}
            // style={{
            //   color: "green",
            //   fontSize: "60px",
            //   border: "10px solid green",
            //   borderRadius: "50px",
            //   padding: "10px",
            // }}
            class="fa fa-ban"
          ></i>
          <h2 className="popup-heading" style={{ marginTop: "20px" }}>
            Failed
          </h2>
          <p>Coupons not found</p>
          <button
            className="btn btn-cannaby"
            style={{ marginTop: "0" }}
            onClick={closeNotFoundPopUp}
          >
            OK
          </button>
        </div>
      </Popup>
    </div>
  );
}
