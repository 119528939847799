import { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import BasicInfoPage from "./sub-pages/BasicInfoPage";
import NotificationSettingsPage from "./sub-pages/NotificationSettingsPage";
import MediasPage from "./sub-pages/MediaPage";
import SocialLinkPage from "./sub-pages/SocialLinkPage";
import InvokeLayoutComponent from "../../components/common/InvokeLogout";

function GeneralSettingsPage() {
  const tabs = [
    {
      title: "Basic Info",
      id: "basic-info",
    },
    {
      title: "Media",
      id: "media",
    },
    // {
    //   title: "Social Link",
    //   id: "social-link",
    // },
    {
      title: "Notification Settings",
      id: "notification-settings",
    },
  ];

  const { search } = useLocation();
  const [currentTab, setCurrentTab] = useState(tabs[0].id);
  useEffect(
    () => {
      const query = new URLSearchParams(search);
      setCurrentTab(query.get("tab") ?? currentTab);
    },
    //eslint-disable-next-line
    [search]
  );
  const [shouldLogout, setShouldLogout] = useState(false);
  const invokeLogout = () => setShouldLogout(true);
  return (
    <div className="card card-pro" style={{ background: "white" }}>
      {/* Navigations */}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "3em",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "1em 2em",
          borderBottom: "2px solid lightgrey",
          flexGrow: 1,
        }}>
        {tabs.map((obj, i) => {
          return (
            <Link key={i} to={`?tab=${obj.id}`}>
              <button
                style={{
                  backgroundColor: obj.id === currentTab ? "white" : "#dfe4ed",
                  padding: "0.5em 1.5em",
                  cursor: "pointer",
                  color: "#1F6355",
                  border: "none",
                  fontWeight: "bold",
                  borderRadius: "10px",
                  boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                }}>
                {obj.title}
              </button>
            </Link>
          );
        })}
      </div>
      {/* tab-contents */}
      {currentTab === "basic-info" && (
        <BasicInfoPage invokeLogout={invokeLogout} />
      )}
      {currentTab === "media" && <MediasPage invokeLogout={invokeLogout} />}
      {currentTab === "social-link" && (
        <SocialLinkPage invokeLogout={invokeLogout} />
      )}
      {currentTab === "notification-settings" && (
        <NotificationSettingsPage invokeLogout={invokeLogout} />
      )}
      {shouldLogout && <InvokeLayoutComponent />}
    </div>
  );
}

export default GeneralSettingsPage;
