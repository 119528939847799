import axios from "axios";

const customInstance = axios.create({
  baseURL: "https://greenbits.bleaum.dev/",
  // baseURL: "https://dev-cc.herokuapp.com",
  // baseURL: "https://greenbits-staging.herokuapp.com/",
  // baseURL: "http://localhost:8080",
  headers: { Accept: "application/json" },
  withCredentials: true,
});

export default customInstance;
