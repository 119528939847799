import { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Fragment } from "react";
import { CircularProgress, Dialog } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useEffect } from "react";
import debounce from "../../helpers/debounce";
import url from "../../config/axios";
import Chip from "@mui/material/Chip";
import Box from "@mui/material/Box";
//here disabled is a key value pair of the productId we can't choose
//previousChosen is an array of previosulyChosen products
export const ProductPicker = ({
  single = true,
  handleClose,
  previouslyChoosen = [],
  disabled = {},
  handleDone,
}) => {
  const getKeyValuePairFromArray = (arr = []) => {
    console.log("Previously choosen is: ", arr);
    if (Array.isArray(arr)) {
      let pair = {};
      arr.forEach((item) => {
        if (item.productID) {
          pair[item.productID] = item;
        }
      });
      return pair;
    }
    return {};
  };

  //its an hashmap
  const [pickedProducts, setPickedProducts] = useState(
    getKeyValuePairFromArray(previouslyChoosen)
  );

  const handlePush = (dataToPush) => {
    if (single && dataToPush.productID) {
      setPickedProducts({
        [dataToPush.productID]: dataToPush,
      });
    } else {
      setPickedProducts({
        ...pickedProducts,
        [dataToPush.productID]: dataToPush,
      });
    }
  };

  const handleRemoveByKey = (key) => {
    let data = { ...pickedProducts };
    delete data[key];
    setPickedProducts(data);
  };

  //products manipulation logic
  const [inventory, setInventory] = useState([]);
  const initialPaginationData = {
    page: 1,
    limit: 500,
    latestFirst: 1,
    search: "",
  };
  const [queryData, setQueryData] = useState({
    ...initialPaginationData,
  });

  //   const [notFound, setNotfound] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [previousSearchTerm, setPreviousSearchTerm] = useState("");
  const [loading, setLoading] = useState(true);
  const performSearch = debounce(() => {
    if ((searchTerm + "").trim() !== (previousSearchTerm + "").trim()) {
      setPreviousSearchTerm(searchTerm);
      handleChange("search", searchTerm, true);
    } else if (searchTerm === "") {
      setPreviousSearchTerm("");
      handleChange("search", "", true);
    }
  }, 1500);
  /**
   *
   * @param {string} propName property to change
   * @param {*} propValue corresponding value of that property
   * @param {*} flush changes every other values to initial data
   */
  const handleChange = (propName, propValue, flush = false) => {
    let query = {};
    if (flush) {
      query = {
        ...initialPaginationData,
        [propName]: propValue,
      };
    } else {
      query = { ...queryData, [propName]: propValue };
    }
    setQueryData({ ...query });
  };
  useEffect(
    () => {
      if (!loading) {
        performSearch();
      }
    },
    //eslint-disable-next-line
    [searchTerm]
  );
  const [paginationData, setPaginationData] = useState({
    currentPage: 1,
    perPage: 500,
    totalPages: 0,
    total: 0,
  });
  //call to fetch data after parsing
  useEffect(
    () => {
      GetAllProducts({ ...queryData });
    },
    //eslint-disable-next-line
    [queryData]
  );
  const GetAllProducts = () => {
    setLoading(true);
    let queryParams = { ...queryData };
    //sanity
    Object.keys(queryParams).forEach((key) => {
      if (!queryParams[key]) {
        delete queryParams[key];
      }
    });
    url
      .get(`/v2/admin/inventories/paginated`, {
        params: {
          ...queryParams,
        },
      })
      .then(async (res) => {
        // console.log('res', res)
        if (res.data.Message === "Success") {
          if (!res.data?.data?.inventories?.length) {
            // setNotfound(true);
          }
          setInventory(res.data?.data?.inventories);
          console.log(res?.data?.data?.paginationData);
          setPaginationData({
            ...paginationData,
            ...res?.data?.data?.paginationData,
          });
          //   console.log("Finished");
        } else {
          console.error(res);
        }
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        console.error(e?.response);
      });
  };
  return (
    <Dialog
      open={true}
      fullScreen={true}
      onClose={() => {
        // if (!inProgress) {
        //   handleClose();
        // }
      }}>
      <div
        style={{
          height: "100vh",
          width: "100%",
          display: "flex",
          flexDirection: "column",
        }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "1em 1.5em",
            height: "auto",
            // backgroundColor: "green",
            alignItems: "center",
          }}>
          <h3>{single ? "Choose Product" : "Choose Products"}</h3>
          <CloseIcon
            style={{ color: "black", cursor: "pointer" }}
            onClick={() => {
              handleClose(false);
            }}
          />
        </div>
        <div
          style={{
            padding: "1em",
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
            overflow: "auto",
            justifyContent: "flex-start",
            alignItems: "center",
          }}>
          <div
            className="row"
            style={{
              width: "100%",
              paddingBottom: "1em",
              flexDirection: "row-reverse",
              alignItems: "center",
            }}>
            <div className="col-md-4">
              <div className="input-group">
                <input
                  type="text"
                  className="form-control table-search"
                  placeholder="Search Product"
                  aria-label="Input group example"
                  aria-describedby="btnGroupAddon"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
                <div className="input-group-prepend">
                  <div
                    className="input-group-text table-search-icon"
                    id="btnGroupAddon">
                    <i className="fas fa-search"></i>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-8">
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: 0.5,
                }}>
                {Object.keys(pickedProducts).length ? (
                  <Fragment>
                    {Object.keys(pickedProducts).map((value, i) => (
                      <Chip
                        key={i}
                        label={`${pickedProducts[value].product.name}`}
                        variant="outlined"
                        onDelete={() => handleRemoveByKey(value)}
                      />
                    ))}
                  </Fragment>
                ) : (
                  "No product(s) selected "
                )}
              </Box>
            </div>
          </div>
          {loading ? (
            <CircularProgress />
          ) : (
            <div style={{ width: "100%" }}>
              {inventory.length ? (
                <TableContainer component={Paper}>
                  <Table aria-label="Available Products">
                    <TableHead>
                      <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>Brand</TableCell>
                        <TableCell>Supplier</TableCell>
                        <TableCell align="center">Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {inventory.map((element, i) => (
                        <TableRow
                          key={i}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}>
                          <TableCell component="th" scope="row">
                            {element.product.name}
                          </TableCell>
                          <TableCell>
                            {element.product?.brandName ?? "Not provided"}
                          </TableCell>
                          <TableCell>
                            {element.product?.extras?.supplier ??
                              "Not Provided"}
                          </TableCell>
                          <TableCell align="center">
                            <button
                              style={{
                                padding: "1em 4em",
                                cursor: "pointer",
                                border: "none",
                                fontWeight: "bold",
                                borderRadius: "10px",
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                gap: "1em",
                                backgroundColor: `${
                                  pickedProducts[element.productID]
                                    ? "#ffcccb"
                                    : "lightskyblue"
                                }`,
                              }}
                              onClick={() =>
                                pickedProducts[element.productID]
                                  ? handleRemoveByKey(element.productID)
                                  : handlePush(element)
                              }>
                              {pickedProducts[element.productID]
                                ? "Remove"
                                : "Choose"}
                            </button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <div
                  className="row"
                  style={{ alignItems: "center", justifyContent: "center" }}>
                  No products found
                </div>
              )}
            </div>
          )}
        </div>

        {!loading && (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "1em 1.5em",
              height: "auto",
              alignItems: "center",
            }}>
            <div className="pagination">
              {paginationData.totalPages !== 0 &&
                paginationData.currentPage <= paginationData.totalPages && (
                  <Fragment>
                    <span>
                      {`${
                        paginationData.perPage *
                          (paginationData.currentPage - 1) +
                        1
                      } - ${
                        paginationData.perPage *
                          (paginationData.currentPage - 1) +
                        inventory.length
                      } of ${paginationData.total ?? 0}`}
                    </span>
                    <div
                      className={`btn ${
                        paginationData.currentPage <= 1 ? "" : "cannaby-light"
                      }`}
                      onClick={() => {
                        if (!(paginationData.currentPage <= 1)) {
                          handleChange("page", paginationData.currentPage - 1);
                        }
                      }}
                      role="button">
                      <i className="fas fa-chevron-left"></i>
                    </div>
                    <div
                      onClick={() => {
                        if (
                          !(
                            paginationData.currentPage >=
                            paginationData.totalPages
                          )
                        ) {
                          handleChange("page", paginationData.currentPage + 1);
                        }
                      }}
                      className={`btn ${
                        paginationData.currentPage >= paginationData.totalPages
                          ? ""
                          : "cannaby-light"
                      }`}
                      role="button">
                      <i className="fas fa-chevron-right"></i>
                    </div>
                  </Fragment>
                )}
            </div>
            <button
              style={{
                padding: "1em 4em",
                cursor: "pointer",
                border: "none",
                fontWeight: "bold",
                borderRadius: "10px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                gap: "1em",
                backgroundColor: "lightgreen",
              }}
              onClick={() => {
                console.log("Picked ", pickedProducts);
                handleDone(pickedProducts);
              }}>
              Done
            </button>
          </div>
        )}
      </div>
    </Dialog>
  );
};
