/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import "./Categories.css";
import { useEffect, useState, useCallback, useRef, Fragment } from "react";
import url from "../../config/axios";
import storage from "../../config/firebase";
// import moment from "moment";
import Cropper from "react-easy-crop";
import Slider from "@mui/material/Slider";
import getCroppedImg from "../../helpers/cropImage";
import Dialog from "@mui/material/Dialog";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import Popup from "reactjs-popup";
import blank from "../../assets/defaultImage.jpg";
import debounce from "../../helpers/debounce";
import { serializeQueryStringsFromObject } from "../../helpers/serializeToQueryStrings";
import { useHistory, useLocation } from "react-router-dom";
import InvokeLayoutComponent from "../common/InvokeLogout";

const Categories = () => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [showCrop, setShowCrop] = useState(false);
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const history = useHistory();
  const [categories, setCategories] = useState([]);
  const [editBoolean, setEditBoolean] = useState(false);
  const [showComponent, setComponent] = useState(false);
  const [parentCategory, setParentCategory] = useState("");
  const [categoryName, setCategoryName] = useState("");
  const [categoryID, setCategoryID] = useState("");
  const [slug, setSlug] = useState("");
  const [image, setImage] = useState("");
  const [file, setFile] = useState("");
  const notFoundRef = useRef();

  const color = ["#03b7a5", "#fc3986", "#ab48d5", "#2bb72b", "#bdd72a"];

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const showCroppedImage = useCallback(
    async (e) => {
      e.preventDefault();
      try {
        const croppedImage = await getCroppedImg(image, croppedAreaPixels, 0);
        console.log("donee", { croppedImage });
        localStorage.setItem("ImageSet", croppedImage);
        setImage(croppedImage);
        setShowCrop(false);
        setOpen(false);
      } catch (e) {
        console.error(e);
      }
    },
    [croppedAreaPixels, image]
  );

  const updateCategory = async (blob) => {
    // console.log("Blob is: ", blob);
    await url
      .put(`/category/?categoryID=${categoryID}`, {
        parentCategory: parentCategory,
        name: categoryName,
        slug: slug,
        image: blob,
      })
      .then((res) => {
        // console.log("Updated data is: ", res.data);
        if (res.data.Message === "Success") {
          setParentCategory("");
          setCategoryName("");
          setSlug("");
          setComponent(false);
          setEditBoolean(false);
          setCategories([
            ...categories.map((obj) =>
              obj?.categoryID === res?.data?.data?.categoryID
                ? res?.data?.data
                : obj
            ),
          ]);
        } else {
          alert("Error: Something went wrong");
        }
      })
      .catch((e) => console.log(e));
  };

  const editCategory = (category) => {
    localStorage.setItem("category", JSON.stringify(category));
    console.log(category);
    // console.log('category', category);
    setCategoryID(category.categoryID);
    setParentCategory(
      category.parentCategory === ""
        ? "Select Parent Category"
        : category.parentCategory
    );
    setCategoryName(category?.name ?? "");
    setSlug(category?.slug ?? "");
    setImage(category.image);
    setEditBoolean(true);
    setComponent(!showComponent);
  };

  const imageSelector = (event) => {
    if (event.target.files[0]) {
      setImage(URL.createObjectURL(event.target.files[0]));
      setFile(event.target.files[0]);
      setOpen(true);
      setShowCrop(true);
    }
  };

  const closeComp = () => {
    setComponent(false);
  };

  const fileUpdate = () => {
    // const storageRef = ref(storage, `images/${file.name}`);
    if (file !== "") {
      const uploadTask = storage
        .ref(`images/${file.name}`)
        .putString(localStorage.getItem("ImageSet"), "data_url");

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // const progress = Math.round(
          //   (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          // );
        },
        async (error) => {
          // console.log(error);
          await updateCategory("");
        },
        () => {
          storage
            .ref("images")
            .child(file.name)
            .getDownloadURL()
            .then(async (blob) => {
              // console.log(blob);
              await updateCategory(blob);
            });
        }
      );
    } else {
      updateCategory(image);
    }
  };

  const [invokeLogOut, setInvokeLogout] = useState(false);
  const initialPaginationData = {
    page: 1,
    limit: 30,
    latestFirst: 1,
    search: "",
  };
  const [queryData, setQueryData] = useState({
    ...initialPaginationData,
  });
  const [searchTerm, setSearchTerm] = useState("");
  const [previousSearchTerm, setPreviousSearchTerm] = useState("");
  //whole editable object will be place here
  const [currentEditingIndex, setCurrentEditingIndex] = useState(null);
  const performSearch = debounce(() => {
    if ((searchTerm + "").trim() !== (previousSearchTerm + "").trim()) {
      setPreviousSearchTerm(searchTerm);
      handleChange("search", searchTerm, true);
    } else if (searchTerm === "") {
      setPreviousSearchTerm("");
      handleChange("search", "", true);
    }
  }, 1500);
  useEffect(
    () => {
      if (parsed && !loading) {
        performSearch();
      }
    },
    //eslint-disable-next-line
    [searchTerm]
  );
  //parse passed queries from the URL first
  const { search } = useLocation();
  const [parsed, setParsed] = useState(false);
  useEffect(
    () => {
      const query = new URLSearchParams(search);

      let page =
        Number.isNaN(query.get("page")) || !query.get("page")
          ? 1
          : +query.get("page");
      let limit =
        Number.isNaN(query.get("limit")) || !query.get("limit")
          ? 30
          : +query.get("limit");
      let latestFirst =
        Number.isNaN(query.get("latestFirst")) || !query.get("latestFirst")
          ? 1
          : +query.get("latestFirst");
      let searchParams = query.get("search");
      let queryParams = {
        ...queryData,
        page,
        limit,
        latestFirst,
        search: searchParams,
      };
      setSearchTerm(searchParams);
      setQueryData({ ...queryParams });
      setParsed(true);
    },
    //eslint-disable-next-line
    []
  );

  const [paginationData, setPaginationData] = useState({
    currentPage: 1,
    perPage: 30,
    totalPages: 0,
    total: 0,
  });
  const [loading, setLoading] = useState(true);

  const [formValidationError, setFormValidationErrors] = useState({});
  const validateForm = () => {
    let data = { ...formValidationError };
    if (categoryName.trim().length === 0) {
      data = { ...data, catError: "Name of the category is required" };
    } else {
      let { catError, ...others } = data;
      data = { ...others };
    }
    setFormValidationErrors(data);
  };
  useEffect(() => {
    validateForm();
  }, [categoryName, slug]);

  //call to fetch data after parsing
  useEffect(
    () => {
      if (parsed) {
        GetAllCategories({ ...queryData });
      }
    },
    //eslint-disable-next-line
    [parsed, queryData]
  );

  /**
   *
   * @param {string} propName property to change
   * @param {*} propValue corresponding value of that property
   * @param {*} flush changes every other values to initial data
   */
  const handleChange = (propName, propValue, flush = false) => {
    if (currentEditingIndex === null) {
      let query = {};
      if (flush) {
        query = {
          ...initialPaginationData,
          [propName]: propValue,
        };
      } else {
        query = { ...queryData, [propName]: propValue };
      }
      const queryString = serializeQueryStringsFromObject(query);
      history.push(`/categories${queryString}`);
      setQueryData({ ...query });
    }
  };

  const GetAllCategories = () => {
    setLoading(true);
    let queryParams = { ...queryData };
    //sanity
    Object.keys(queryParams).forEach((key) => {
      if (!queryParams[key]) {
        delete queryParams[key];
      }
    });
    url
      .get(`/v2/admin/categories/paginated`, {
        params: {
          ...queryParams,
        },
      })
      .then(async (res) => {
        // console.log('res', res)
        if (res.data.Message === "Success") {
          if (!res.data?.data?.categories?.length) {
            notFoundRef.current.open();
          }
          setCategories(res.data?.data?.categories);
          console.log(res?.data?.data?.paginationData);
          setPaginationData({
            ...paginationData,
            ...res?.data?.data?.paginationData,
          });
        } else {
          console.error(res);
        }
        setLoading(false);
      })
      .catch((e) => {
        if (+e?.response?.status === 401) {
          setInvokeLogout(true);
        }
        setLoading(false);
        console.error(e?.response);
      });
  };

  let num = 0;

  function assignColor() {
    var random = Math.floor(Math.random() * color.length);
    while (num === random) {
      random = Math.floor(Math.random() * color.length);
    }
    num = random;
    var value = color[random];
    console.log(value, "VALUES");
    return value;
  }

  return (
    <div className="row">
      <div className="col-md-8">
        <div className="card card-cat">
          {/* <button className="btn btn-primary btn-cat" onClick={() => setAddCategoryComponent()}>
            <i className="fas fa-plus-circle"></i>
            Add Category
          </button> */}
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-6">
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control table-search"
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      placeholder="Search by category name"
                      aria-label="Input group example"
                      aria-describedby="btnGroupAddon"
                    />
                    <div className="input-group-prepend">
                      <div
                        className="input-group-text table-search-icon"
                        id="btnGroupAddon"
                      >
                        <i className="fas fa-search"></i>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-2 filters-bar">
                  <div className="dropdown">
                    {/* <a className="btn cannaby-light" href="/" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <i className="fas fa-filter"></i>
                    </a> */}

                    <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuLink"
                    >
                      <a className="dropdown-item" href="/">
                        Action
                      </a>
                      <a className="dropdown-item" href="/">
                        Another action
                      </a>
                      <a className="dropdown-item" href="/">
                        Something else here
                      </a>
                    </div>
                  </div>
                  <div className="dropdown">
                    {/* <a className="btn cannaby-light" href="/" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <i className="fas fa-filter"></i>
                    </a> */}

                    <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuLink"
                    >
                      <a className="dropdown-item" href="/">
                        Action
                      </a>
                      <a className="dropdown-item" href="/">
                        Another action
                      </a>
                      <a className="dropdown-item" href="/">
                        Something else here
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 pagination">
                  {paginationData.totalPages !== 0 &&
                    paginationData.currentPage <= paginationData.totalPages && (
                      <Fragment>
                        <span>
                          {`${paginationData.perPage *
                            (paginationData.currentPage - 1) +
                            1
                            } - ${paginationData.perPage *
                            (paginationData.currentPage - 1) +
                            categories.length
                            } of ${paginationData.total ?? 0}`}
                        </span>
                        <div
                          className={`btn ${paginationData.currentPage <= 1
                            ? ""
                            : "cannaby-light"
                            }`}
                          onClick={() => {
                            if (!(paginationData.currentPage <= 1)) {
                              handleChange(
                                "page",
                                paginationData.currentPage - 1
                              );
                            }
                          }}
                          role="button"
                        >
                          <i className="fas fa-chevron-left"></i>
                        </div>
                        <div
                          onClick={() => {
                            if (
                              !(
                                paginationData.currentPage >=
                                paginationData.totalPages
                              )
                            ) {
                              handleChange(
                                "page",
                                paginationData.currentPage + 1
                              );
                            }
                          }}
                          className={`btn ${paginationData.currentPage >=
                            paginationData.totalPages
                            ? ""
                            : "cannaby-light"
                            }`}
                          role="button"
                        >
                          <i className="fas fa-chevron-right"></i>
                        </div>
                      </Fragment>
                    )}
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <table className="table table-striped-cust">
                <thead>
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col"></th>
                    <th scope="col"></th>
                    <th scope="col"></th>

                    <th scope="col"></th>
                    <th scope="col"></th>
                    <th scope="col">Products</th>
                    <th scope="col">Parent Category</th>
                    <th scope="col" className="hidden">
                      Status
                    </th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {categories.map((category, i) => {
                    return (
                      <tr key={i}>
                        <td colSpan="6">
                          {category.image ? (
                            <div>
                              <img
                                alt="yo1"
                                src={category.image}
                                className="img-fluid "
                                width="40"
                              />
                              {category.name}
                            </div>
                          ) : (
                            <div style={{ display: "flex" }}>
                              <div
                                className="image-thumbnail-div"
                                style={{
                                  backgroundColor: assignColor(),
                                  width: "40px",
                                  borderRadius: "5px",
                                  height: "40px",
                                }}
                              >
                                <span>{category?.name?.substring(0, 1)}</span>
                              </div>
                              <span style={{ paddingTop: "10px" }}>
                                {category.name}
                              </span>
                            </div>
                          )}
                        </td>
                        <td>
                          {category.countInventory.length
                            ? category.countInventory[0].count
                            : 0}
                        </td>
                        <td>{category.parentCategory || "_"}</td>
                        {/* <td>{moment(category.updatedAt).format(" MMM. Do YYYY, h:mm A")}</td> */}
                        <td className="status hidden">
                          {category.status ? (
                            <span>Published</span>
                          ) : (
                            <span>Not Published</span>
                          )}
                        </td>
                        <td>
                          <i
                            onClick={() => editCategory(category)}
                            className="fas fa-pen"
                          ></i>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div className="col-md-4">
        {/* {showComponent && <AddCategory editBoolean={editBoolean} setEditBoolean={setEditBoolean} /> } */}
        {showComponent && (
          <div className="card">
            <div>
              <div className="headerEA">
                <h4>{editBoolean ? "Edit Categories" : "Add Categories"}</h4>
                <button className="btn close-btn" onClick={closeComp}>
                  <i className="fas fa-times"></i>
                </button>
              </div>

              <br></br>
              <form
                className="parent-form"
                onSubmit={(e) => e.preventDefault()}
              >
                {/* <div className="group-comb">
                  <div className="form-group">
                    <label htmlFor="exampleFormControlSelect1">
                      Parent Category
                    </label>
                    <select
                      onChange={(e) => setParentCategory(e.target.value)}
                      className="form-control"
                      id="exampleFormControlSelect1"
                    >
                      <option value="">
                        {editBoolean
                          ? parentCategory
                          : "Select Parent Category"}
                      </option>
                      {categories.map((category, i) => (
                        <option key={i} value={category.name}>
                          {category.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div> */}

                <div className="group-comb">
                  <div className="form-group">
                    <label htmlFor="exampleFormControlInput1">
                      Category Name*
                    </label>
                    <input
                      type="text"
                      value={categoryName}
                      onChange={(e) => setCategoryName(e.target.value)}
                      className="form-control"
                      id="exampleFormControlInput1"
                      placeholder="Category Name"
                    />
                    {!!formValidationError.catError && (
                      <span style={{ color: "red" }}>
                        {formValidationError.catError}
                      </span>
                    )}
                  </div>
                </div>
                {/* <div className="group-comb">
                  <div className="form-group">
                    <label htmlFor="exampleFormControlInput1">Slug*</label>
                    <input
                      type="text"
                      value={slug}
                      onChange={(e) => setSlug(e.target.value)}
                      className="form-control"
                      // id="exampleFormControlInput1"
                      placeholder="Slug"
                    />
                    {!!formValidationError.slugError && (
                      <span style={{ color: "red" }}>
                        {formValidationError.slugError}
                      </span>
                    )}
                  </div>
                </div> */}

                <div className="group-comb">
                  <div className="form-group img-drop">
                    <label className="custom-file-upload">
                      <input
                        type="file"
                        id="image"
                        name="Image"
                        onChange={imageSelector}
                        accept="image/png, image/jpeg"
                      />
                      Browse
                    </label>
                    <img
                      alt=""
                      width="150"
                      height="120"
                      src={image}
                      className="rounded mx-auto d-block galimage"
                    ></img>
                    {showCrop && (
                      <Dialog
                        fullScreen={fullScreen}
                        open={open}
                        fullWidth={true}
                        onClose={() => setOpen(false)}
                        aria-labelledby="responsive-dialog-title"
                      >
                        <div
                          className="App"
                          style={{
                            padding: "1em",
                            width: "auto",
                            height: "auto",
                          }}
                        >
                          <div className="crop-container">
                            <Cropper
                              image={image}
                              crop={crop}
                              zoom={zoom}
                              aspect={4 / 3}
                              onCropChange={setCrop}
                              onCropComplete={onCropComplete}
                              onZoomChange={setZoom}
                            />
                          </div>
                          <div className="controls">
                            <Slider
                              value={zoom}
                              min={1}
                              max={3}
                              step={0.1}
                              aria-labelledby="Zoom"
                              onChange={(e, zoom) => setZoom(zoom)}
                            // classes={{ root: "slider" }}
                            />
                          </div>
                          <div>
                            <button
                              className="btn btn-primary crop-btn"
                              onClick={(e) => showCroppedImage(e)}
                              style={{
                                textAlign: "center",
                                width: "auto",
                                padding: "10px 30px",
                              }}
                            >
                              Crop
                            </button>
                          </div>
                        </div>
                      </Dialog>
                    )}
                  </div>
                </div>

                <div className="group">
                  {editBoolean && (
                    <button
                      type="button"
                      onClick={fileUpdate}
                      disabled={Object.keys(formValidationError).length !== 0}
                      className="btn btn-primary m-2"
                    >
                      Update
                    </button>
                  )}
                  {/* {editBoolean && <button type="button" onClick={deleteCategory} className="btn btn-danger m-2"><span><i className="fa fa-trash-alt"></i></span></button>} */}
                  {/* {!editBoolean && (
                    <button
                      type="button"
                      onClick={fileUpload}
                      className="btn btn-primary m-2">
                      Publish
                    </button>
                  )} */}
                </div>
                {editBoolean ? (
                  <p className="createdOn">
                    {/* <br />
                    Created on{" "}
                    {moment(createdDAte).format(" MMM. Do YYYY, h:mm A")}{" "} */}
                  </p>
                ) : (
                  ""
                )}
              </form>
            </div>
          </div>
        )}
      </div>
      {invokeLogOut && <InvokeLayoutComponent />}
      <Popup
        ref={notFoundRef}
        position="center"
        onClose={() => {
          notFoundRef.current.close();
          setSearchTerm("");
        }}
      >
        <div style={{ textAlign: "center" }}>
          <i
            style={{
              color: "red",
              fontSize: "70px",
            }}
            // style={{
            //   color: "green",
            //   fontSize: "60px",
            //   border: "10px solid green",
            //   borderRadius: "50px",
            //   padding: "10px",
            // }}
            class="fa fa-ban"
          ></i>
          <h2 className="popup-heading" style={{ marginTop: "20px" }}>
            Failed
          </h2>
          <p>Category(s) not found</p>
          <button
            className="btn btn-cannaby"
            style={{ marginTop: "0" }}
            onClick={() => {
              notFoundRef.current.close();
              setSearchTerm("");
            }}
          >
            OK
          </button>
        </div>
      </Popup>
    </div>
  );
};

export default Categories;
